<template>
    <div>
        <AppSubHeader link='contratos' title='Mi paz y salvo' />
        <main class="m-content container mb-5">

            <div class="row">
                <div class="col-xl-6 mx-auto">
                    <pazysalvoVue />
                </div>
            </div>

        </main>
    </div>
</template>
    
<script>

import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import pazysalvoVue from "./user/pazysalvo.vue";

export default {
    name: 'Document',
    components: {
        AppSubHeader,
        pazysalvoVue
    },
}

</script>